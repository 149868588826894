<template>

  <b-card no-body>
    <div class="m-1">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <v-select
              v-model="perPage"
              class="per-page-selector d-inline-block mx-50"
              :options="perPageOptions"
              :clearable="false"/>
        </b-col>
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."/>
            <header-table :new-click="registerShowModal" :re-fetch-data="reFetchData"></header-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
        ref="refListTable"
        primary-key="id"
        empty-text="No record found"
        :class="`position-relative table-sticky ${totalCount > 0 && totalCount < 5 ? 'dropdown-h150': ''}`"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive striped bordered hover show-empty>

      <template #cell(id)="data">
        <id-column-table :data="data.value" :route-name="'student-edit'"></id-column-table>
      </template>

      <template #cell(profile_picture_url)="data">
        <b-avatar
            :src="getApiFile(data.value)"
            :text="avatarText(data.item.first_name + ' ' + data.item.last_name)"
            :variant="`light-primary`"
            size="60px"/>
      </template>

      <template #cell(is_accepted)="data">
        <b-badge pill :variant="`light-success`" class="text-capitalize" v-if="data.value === 1">
          YES
        </b-badge>
      </template>

      <template #cell(date_of_birth)="data">
        <date-column-table :data="data.value"></date-column-table>
      </template>

      <template #cell(status)="data">
        <b-badge pill :variant="`light-danger`" class="text-capitalize" v-if="data.value === 0">
          Passive
        </b-badge>
        <b-badge pill :variant="`light-secondary`" class="text-capitalize" v-if="data.value === 1">
          InActive
        </b-badge>
        <b-badge pill :variant="`light-primary`" class="text-capitalize" v-if="data.value === 2">
          Active
        </b-badge>
        <b-badge pill :variant="`light-success`" class="text-capitalize" v-if="data.value === 3">
          Approve
        </b-badge>
        <b-badge pill :variant="`light-danger`" class="text-capitalize" v-if="data.value === 4">
          Decline
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="text-nowrap">
          <b-dropdown text="Dropdown" variant="link" no-caret toggle-class="p-0" dropleft v-if="roleType!==1">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
            </template>
            <b-dropdown-item @click="approve(data.item)">
              <feather-icon icon="CheckCircleIcon"/>
              <span class="align-middle ml-50">Approve</span>
            </b-dropdown-item>
            <b-dropdown-item @click="decline(data.item)">
              <feather-icon icon="XCircleIcon"/>
              <span class="align-middle ml-50">Decline</span>
            </b-dropdown-item>
            <b-dropdown-item @click="changeStatusShowModal(data.item)">
              <feather-icon icon="CheckIcon"/>
              <span class="align-middle ml-50">Assign Partner</span>
            </b-dropdown-item>
            <b-dropdown-item @click="websiteLogin(data.item)">
              <feather-icon icon="LockIcon"/>
              <span class="align-middle ml-50">Login as This User</span>
            </b-dropdown-item>
            <b-dropdown-item @click="viewResume(data.item)">
              <feather-icon icon="EyeIcon"/>
              <span class="align-middle ml-50">View Resume</span>
            </b-dropdown-item>
            <b-dropdown-item @click="viewServiceAgreement(data.item)">
              <feather-icon icon="EyeIcon"/>
              <span class="align-middle ml-50">View Service Agreement</span>
            </b-dropdown-item>
            <b-dropdown-item @click="viewPayment(data.item)">
              <feather-icon icon="CreditCardIcon"/>
              <span class="align-middle ml-50">Payment</span>
            </b-dropdown-item>
            <b-dropdown-item @click="remove(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Remove</span>
            </b-dropdown-item>
          </b-dropdown>
          <feather-icon :id="`row-${data.item.id}-prev`" :icon="roleType!==1 ? 'EditIcon': 'EyeIcon'" size="16" @click="roleType!==1 ? $router.push({ name: 'student-edit', params: { id: data.item.id }}) : viewResume(data.item)" class="mx-1"/>
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <footer-table :data-meta="dataMeta"></footer-table>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              v-model="currentPage"
              :total-rows="totalCount"
              :per-page="perPage"
              first-number last-number>

            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18"/>
            </template>

            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18"/>
            </template>

          </b-pagination>
        </b-col>
      </b-row>
    </div>

    <Overlay :busy="busy"></Overlay>

    <b-modal v-model="changeStatusModalShow" ref="change-status-modal" title="Assign Partner" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleChangeStatusModal" centered no-close-on-backdrop>
      <b-form @submit.stop.prevent="submitChangeStatusModal" ref="refFormObserver">
        <b-form-group label="* Partner">
          <v-select v-model="changePartnerId" :options="partnerOptions" :reduce="val => val.value" :clearable="false"/>
        </b-form-group>
      </b-form>
    </b-modal>

    <form :action="websiteUrl" method="post" id="record-form">
      <input type="hidden" name="panel-key" id="record-key" :value="panelKey"/>
      <input type="hidden" name="email" id="record-email" :value="recordEmail"/>
      <input type="hidden" name="token" id="record-token" :value="recordToken"/>
    </form>

    <b-modal v-model="registerModalShow" ref="register-modal" title="Add Student" ok-title="Save" cancel-title="Cancel" cancel-variant="outline-secondary" @ok="handleRegisterModal" centered no-close-on-backdrop>
      <b-form @submit.stop.prevent="submitRegisterModal" ref="refFormObserver">
        <b-form-group label="* First Name">
          <b-form-input trim placeholder="First Name" v-model="registerInfo.first_name"/>
        </b-form-group>
        <b-form-group label="* Last Name">
          <b-form-input trim placeholder="Last Name" v-model="registerInfo.last_name"/>
        </b-form-group>
        <b-form-group label="* Email Address">
          <b-form-input trim placeholder="Email Address" v-model="registerInfo.email"/>
        </b-form-group>
        <b-form-group label="* Password">
          <b-form-input trim placeholder="Password" v-model="registerInfo.password"/>
        </b-form-group>
        <b-form-group label="Register Payment Amount">
          <b-form-input trim placeholder="Register Payment Amount" v-model="registerInfo.register_payment_amount"/>
        </b-form-group>
      </b-form>
    </b-modal>

    <b-modal v-model="paymentModalShow" ref="payment-modal" title="Payments" ok-title="Close" cancel-variant="outline-secondary" centered ok-only size="lg">
      <b-row>

      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
              :items="paymentData"
              :fields="paymentFields"
              class="mb-0"
              striped responsive bordered hover>

            <template #cell(created_date)="data">
              <date-time-column-table :data="data.value"></date-time-column-table>
            </template>

            <template #cell(amount)="data">
              <span v-if="data.value > 0">${{ data.value }}</span>
            </template>

            <template #cell(payment_type)="data">
              <span v-if="data.value === 1">Register</span>
              <span v-if="data.value === 2">Profile Type</span>
            </template>

          </b-table>
        </b-col>
      </b-row>
    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay, BFormInvalidFeedback, BFormGroup, BInputGroup, BInputGroupAppend, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import {
  FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable,
  ActiveColumnTable, IdColumnTable, HeaderTable
} from '@/components/Table';

import {onUnmounted, ref} from '@vue/composition-api'
import useList from './useList'
import {avatarText, getApiFile} from "@core/utils/filter";
import vSelect from 'vue-select'
import store from '@/store'
import storeModule from './store'
import partnerModule from "@/views/partner/store"
import Overlay from "@/components/Overlay.vue";
import Vue from "vue";
import {$themeConfig} from "@themeConfig";
import {ValidationProvider} from "vee-validate";
import router from "@/router";
import {toastMessage} from "@core/utils/utils";
import DateTimeColumnTable from "@/components/Table/DateTimeColumnTable.vue";

export default {
  components: {
    DateTimeColumnTable,
    BFormCheckbox,
    ValidationProvider, HeaderTable, FooterTable, RemoveButtonTable, EditButtonTable, DateColumnTable, ActiveColumnTable, IdColumnTable,
    BForm, BFormGroup, BFormInvalidFeedback, BInputGroupAppend, BInputGroup,
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem,
    BPagination, BTooltip, BButtonToolbar, BButtonGroup, BOverlay,

    vSelect,
    Overlay,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_PARTNER_MODULE_NAME = 'store-partner'
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_PARTNER_MODULE_NAME, partnerModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_PARTNER_MODULE_NAME)
      }
    })

    const {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,

      fetchList,
      reFetchData,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
    } = useList()

    const approve = (dataItem) => {
      Vue.swal({
        title: 'Approve?',
        text: 'Record will be approved!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Approve',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/approveItem', {id: dataItem.id}).then(response => {
            reFetchData()
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const decline = (dataItem) => {
      Vue.swal({
        title: 'Decline?',
        text: 'Record will be declined!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Decline',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/declineItem', {id: dataItem.id}).then(response => {
            reFetchData()
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const remove = (dataItem) => {
      Vue.swal({
        title: 'Remove?',
        text: 'Record will be removed!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/removeItem', {id: dataItem.id}).then(response => {
            reFetchData()
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const registerInfo = ref({
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      register_payment_amount: null
    })

    const selectedRecord = ref(null)
    const partnerOptions = ref([])
    const changePartnerId = ref(null)
    const changeStatusModalShow = ref(false)
    const changeStatusShowModal = (dataItem) => {
      selectedRecord.value = dataItem
      partnerOptions.value = []

      store.dispatch('store-partner/fetchItems').then(response => {
        response.data.data.forEach((value, index) => {
          partnerOptions.value.push({label: value.first_name + ' ' + value.last_name, value: value.id})
        })
      }).catch(error => {

      }).finally(message => {

      })

      changeStatusModalShow.value = true
    }
    const handleChangeStatusModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      submitChangeStatusModal()
    }
    const submitChangeStatusModal = () => {

      if (changePartnerId.value === null) {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Partner is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })

        return
      }

      busy.value = true
      store.dispatch('store/assignPartner', {
        student_id: selectedRecord.value.id,
        partner_id: changePartnerId.value,
      }).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Partner assigned.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      selectedRecord.value = null
      changePartnerId.value = null
      changeStatusModalShow.value = false
    }

    const websiteUrl = $themeConfig.app.appWebSiteUrl + '/student/panel-login';
    const panelKey = ref('')
    const recordEmail = ref('')
    const recordToken = ref('')
    const websiteLogin = (dataItem) => {
      document.getElementById("record-key").value = $themeConfig.app.appPanelKey;
      document.getElementById("record-email").value = dataItem.email;
      document.getElementById("record-token").value = dataItem.profile_token;
      document.getElementById("record-form").submit();
    }

    const registerModalShow = ref(false)
    const registerShowModal = () => {
      registerInfo.value = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        register_payment_amount: null
      }
      registerModalShow.value = true
    }
    const handleRegisterModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (registerInfo.value.first_name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'First Name is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (registerInfo.value.last_name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Last Name is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      if (registerInfo.value.password === '') {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: 'Password is required!',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      submitRegisterModal()
    }
    const submitRegisterModal = () => {
      busy.value = true
      store.dispatch('store/register', registerInfo.value).then(response => {
        reFetchData()
        Vue.swal({
          icon: 'success',
          title: 'OK!',
          text: 'Student added.',
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }).finally(message => {
        busy.value = false
      })

      registerModalShow.value = false
    }

    const viewResume = (dataItem) => {
      window.open($themeConfig.app.appWebSiteUrl + '/student/resume?id=' + dataItem.profile_token, '_blank');
    }

    const viewServiceAgreement = (dataItem) => {
      store.dispatch('store/serviceAgreement', {id: dataItem.id}).then(response => {

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl);

      }).catch(error => {
        Vue.swal({
          icon: 'error',
          title: 'Error!',
          text: error.response.data.message,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      })
    }

    const paymentModalShow = ref(false)
    const paymentData = ref([])
    const paymentFields = [
      {key: 'id', label: '#', sortable: false, thStyle: {width: "1%"}},
      {key: 'created_date', label: 'DATE', sortable: false},
      {key: 'payment_type', label: 'PAYMENT TYPE', sortable: false},
      {key: 'amount', label: 'AMOUNT', sortable: false},
    ]

    const viewPayment = (dataItem) => {
      busy.value = true
      store.dispatch('store/payments', {id: dataItem.id}).then(response => {
        paymentData.value = response.data.data
        paymentModalShow.value = true
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.error_message)
        if (error.response.status === 403) {
          router.push({name: 'student-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const roleData = JSON.parse(localStorage.getItem('roleData'))
    const roleType = ref(roleData.role_type)

    return {
      tableColumns,
      perPage,
      currentPage,
      totalCount,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      busy,
      changeStatusModalShow,
      changePartnerId,
      partnerOptions,
      resolveUserRoleVariant,
      resolveUserRoleIcon,

      panelKey,
      recordEmail,
      recordToken,
      websiteUrl,

      registerInfo,
      registerModalShow,

      paymentModalShow,
      paymentData,
      paymentFields,

      roleType,

      registerShowModal,
      handleRegisterModal,
      submitRegisterModal,

      approve,
      decline,

      fetchList,
      reFetchData,
      avatarText,
      getApiFile,
      handleChangeStatusModal,
      submitChangeStatusModal,
      changeStatusShowModal,
      websiteLogin,
      viewResume,
      viewPayment,
      remove,
      viewServiceAgreement,
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
