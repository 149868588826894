import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchItems(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/student/list', {
                        params: queryParams,
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/student/get?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        editItem(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .put('/student/save', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/student/delete?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        approveItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/student/approve?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        declineItem(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/student/decline?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        sportItems(ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/sports/list')
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        sportItem(ctx, {id, student_id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/sports/get?id=${id}&student_id=${student_id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        matchedUniversities(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/student/matched-universities?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        assignPartner(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/connect-partner', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        register(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/register', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        payments(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/student/payment-list?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveGuardian(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save-guardian', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveAcademicFile(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save-academic-file', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveDocumentFile(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save-document-file', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        savePortfolioFile(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save-portfolio-file', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveVideos(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save-videos', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveExtraCurricular(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save-extra-curricular', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveProfileType(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save-profile-type', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        saveUniversityDepartment(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/save-university-department', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeUniversityDepartment(ctx, {id}) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/student/delete-university-department?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeUniversityDepartmentList(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/delete-university-department-list', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        emailUniversityDepartmentList(ctx, dataInfo) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/student/send-email-university-department-list', dataInfo)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        serviceAgreement(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get('/student/pdf-agreement', {
                        params: queryParams
                    })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },        
    },
}
